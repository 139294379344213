import {
  Route,
  BrowserRouter as Router,
  Navigate,
  Routes,
  useNavigate,
} from "react-router-dom";
import Main from "./view/main";
import Post from "./view/post";
import PostEdit from "./view/post-edit";
import History from "./view/history";
import Login from "./view/login";
import React, { useEffect, useState } from "react";
import { User } from "./constant";
import axios, { AxiosResponse } from "axios";

export const UserContext = React.createContext({} as any);

function App() {
  const [user, setUser] = useState(null as unknown as User);
  const [isUserLoading, setIsUserLoading] = useState(false);
  const setUserWithData = (result: AxiosResponse) => {
    setUser({
      id: result.data.id,
      username: result.data.username,
      nickname: result.data.nickname,
      student_id: result.data.student_id,
      email: result.data.email,
      isApproved: result.data.isApproved,
      emoji: result.data.emoji,
    });
    setIsUserLoading(false);
  };
  const logout = () => {
    window.localStorage.removeItem("token");
    window.location.href = "/";
  };
  const store = {
    user,
    setUserWithData,
    logout,
    isUserLoading,
  };
  const getMe = async () => {
    try {
      setIsUserLoading(true);
      const result = await axios.get(
        `${process.env.REACT_APP_API_HOST}/api/users/me`,
        {
          withCredentials: true,
          headers: {
            Authorization: window.localStorage.getItem("token") as string,
          },
        }
      );
      setUserWithData(result);
    } catch (e) {
      setUser(null as unknown as User);
    }
  };
  useEffect(() => {
    if (window.localStorage.getItem("token")) getMe();
  }, []);
  return (
    <UserContext.Provider value={store}>
      <Router>
        <Routes>
          <Route path="/" element={<Main />} />
          <Route path="/post/:id" element={<Post />} />
          <Route path="/post/:id/edit" element={<PostEdit />} />
          <Route path="/post/:id/history" element={<History />} />
          <Route path="/post/:id/history/:historyId" element={<Post />} />
          <Route path="/login" element={<Login />} />
          <Route path="*" element={<Navigate replace to="/" />} />
        </Routes>
      </Router>
    </UserContext.Provider>
  );
}

export default App;
