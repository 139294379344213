import * as S from "./styles";
import Footer from "../footer";
import Header from "../header";

interface LayoutProps {
  children?: React.ReactNode;
}

const Layout = ({ children }: LayoutProps) => {
  return (
    <S.Layout>
      <Header />
      {children}
      <Footer />
    </S.Layout>
  );
};
export default Layout;
