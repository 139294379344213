import { useNavigate } from "react-router-dom";
import * as S from "./styles";

const Header = () => {
  const navigate = useNavigate();
  return (
    <S.Header>
      <S.Logo
        src="/logo.png"
        alt="study.kucc.co.kr logo"
        onClick={() => navigate("/")}
      />
      <S.Subtitle>
        KUCC의 스터디 커리큘럼을 함께 만들어나가는 곳입니다.
      </S.Subtitle>
    </S.Header>
  );
};

export default Header;
