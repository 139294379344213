import styled from "styled-components/macro";

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 3rem;
`;

export const Logo = styled.img`
  max-width: 100%;
  width: 500px;
  cursor: pointer;
`;

export const Subtitle = styled.div`
  font-size: 1.6rem;
`;
