import { Link } from "react-router-dom";
import styled from "styled-components";
import { BREAKPOINT, THEME_COLOR } from "../../constant";

export const Post = styled.div`
  width: 100%;
  max-width: 1000px;
  padding: 3rem 2rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  min-height: 100vh;
`;

export const DoneButton = styled.button`
  background: none;
  border: none;
  padding: 0.5rem 1.5rem;
  border-radius: 1rem;
  font-weight: 700;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.2rem;
  font-size: 1.6rem;
`;

export const Header = styled.div`
  width: 100%;
  display: flex;
  font-size: 1.6rem;
  gap: 0.5rem;
  align-items: center;
  margin-bottom: 3rem;
  border-bottom: 1px solid ${THEME_COLOR.GRAYER};
  padding-bottom: 1rem;
`;

export const ItemTitle = styled.div`
  font-size: 1.8rem;
  display: flex;
  font-weight: 700;
  gap: 1rem;
  img {
    width: 20px;
    height: 20px;
    object-fit: cover;
  }
`;

export const ButtonConatiner = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 2rem;
`;

export const InputContainer = styled.div`
  width: 100%;
  margin: 2rem 0;
  display: flex;
  align-items: center;
  gap: 1rem;
  @media screen and (max-width: ${BREAKPOINT}px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const Input = styled.input`
  width: 100%;
  padding: 0.2rem;
  font-size: 1.6rem;
  outline: none;
  border: none;
  border-bottom: 1px solid #ccc;
`;

export const Memo = styled.div`
  font-weight: 700;
  font-size: 1.6rem;
  width: 12rem;
`;
