import { Link } from "react-router-dom";
import styled, { css } from "styled-components/macro";
import { BREAKPOINT, THEME_COLOR } from "../../constant";

export const Main = styled.div`
  width: 100%;
  min-height: 100%;
  height: max-content;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const StudyList = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 100%;
  width: 1000px;
  margin-top: 10rem;
`;

export const SectionTitle = styled.div`
  width: fit-content;
  font-size: 1.8rem;
  font-weight: 700;
  border-bottom: 0.1rem solid ${THEME_COLOR.PRIMARY};
  margin-bottom: 1rem;
  padding-bottom: 0.2rem;
  small {
    margin-left: 0.2rem;
  }
`;

export const SectionSubtitle = styled.div`
  font-size: 1.6rem;
  margin-bottom: 1rem;
`;

export const ItemContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  margin: 3rem 0;
  @media screen and (max-width: ${BREAKPOINT}px) {
    grid-template-columns: 1fr;
    justify-items: flex-start;
    gap: 1rem;
  }
`;

export const Item = styled(Link)`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
  font-size: 1.4rem;
  padding: 1rem 2rem;
  color: black;
  text-decoration: none;
  @media screen and (max-width: ${BREAKPOINT}px) {
    border-left: 0.1rem solid ${THEME_COLOR.PRIMARY};
    align-items: flex-start;
  }
`;

export const ItemTitle = styled.div`
  font-size: 1.8rem;
  display: flex;
  font-weight: 700;
  gap: 10px;
  img {
    width: 20px;
    height: 20px;
    object-fit: cover;
  }
`;

export const AddButton = styled.button`
  background: none;
  width: 2rem;
  height: 2rem;
  border: none;
  svg {
    width: 2rem;
    height: 2rem;
    fill: ${THEME_COLOR.PRIMARY};
  }
`;

export const SectionHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const ModalContainer = styled.div`
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background: #00000033;
  padding: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Modal = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 50rem;
  max-width: 100%;
  height: max-content;
  background: white;
  padding: 2rem 4rem;
  gap: 2rem;
  border: 1px solid #aaa;
`;

export const ModalText = styled.div`
  font-size: 1.8rem;
  margin-bottom: 1.5rem;
  width: 100%;
  text-align: center;
  font-weight: 800;
  padding-bottom: 1.5rem;
  border-bottom: 1px dashed ${THEME_COLOR.PRIMARY};
`;

export const ModalSection = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  img {
    width: 2rem;
    height: 2rem;
    object-fit: contain;
    margin-right: 1rem;
  }
`;

export const ModalSectionTitle = styled.div`
  font-size: 1.6rem;
  font-weight: 700;
  width: 10rem;
`;

export const Dropdown = styled.select`
  height: 2.5rem;
  outline: none;
  border: none;
`;

interface InputProps {
  isError?: boolean;
}

export const ModalTitle = styled.input<InputProps>`
  border: none;
  border-bottom: 1px solid ${THEME_COLOR.GRAYER};
  height: 2.5rem;
  outline: none;
  width: 100%;
  ${(props) =>
    props.isError &&
    css`
      border-bottom: 1px solid ${THEME_COLOR.PRIMARY};
    `}
`;

export const ErrorMessage = styled.div`
  color: ${THEME_COLOR.PRIMARY};
`;

export const ButtonConatiner = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
`;

export const DoneButton = styled.button`
  background: none;
  border: none;
  padding: 0.5rem 1.5rem;
  border-radius: 1rem;
  font-weight: 700;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.2rem;
  font-size: 1.6rem;
`;

export const LoginButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
`;

export const LoginButton = styled.button`
  width: 8rem;
  height: 4rem;
  border: none;
  background: ${THEME_COLOR.PRIMARY};
  color: white;
  border-radius: 2rem;
`;

export const SignupButton = styled(LoginButton)`
  border: 1px solid #888;
  background: white;
  color: #333;
`;

export const LoginInfo = styled.div`
  font-size: 1.6rem;
  font-weight: 600;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  button {
    background: none;
    border: none;
    color: ${THEME_COLOR.PRIMARY};
    margin-top: 5px;
    display: block;
  }
  small {
    margin-left: 0.2rem;
  }
`;

export const ChosenEmoji = styled.button`
  width: 3rem;
  height: 3rem;
  border: 1px solid ${THEME_COLOR.GRAYER};
  background: none;
`;

export const Picker = styled.div`
  position: absolute;
`;

export const LoadingContainer = styled.div`
  width: 100%;
  min-height: 100%;
  height: max-content;
  display: flex;
  justify-content: center;
  align-items: center;
`;
