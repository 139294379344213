import * as S from "./styles";
import Layout from "../../component/layout";
import { useNavigate, useParams } from "react-router-dom";
import { Editor } from "@toast-ui/react-editor";
import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { Post, User } from "../../constant";
import "@toast-ui/editor/dist/toastui-editor.css";
import { AiOutlineCheck } from "react-icons/ai";
import "@toast-ui/editor/dist/toastui-editor.css";
//@ts-ignore
import codeSyntaxHighlight from "@toast-ui/editor-plugin-code-syntax-highlight/dist/toastui-editor-plugin-code-syntax-highlight-all.js";
import "../../component/global-style/prism-vscode.css";
import { UserContext } from "../../App";

const PostEdit = () => {
  const params = useParams();
  const navigate = useNavigate();
  const [post, setPost] = useState(null as unknown as Post);
  const [memo, setMemo] = useState("");
  const { user } = React.useContext(UserContext);
  const getPost = async () => {
    const result = await axios.get(
      `${process.env.REACT_APP_API_HOST}/api/posts?filters[title][$eq]=${params.id}&populate=*`
    );
    if (!result.data.data[0]) navigate("/");
    setPost(result.data.data[0]);
  };
  useEffect(() => {
    if (!user || !user.isApproved) navigate(-1);
    getPost();
    window.scrollTo({ top: 0 });
  }, []);
  const editor = useRef<Editor>(null);
  const handleSubmit = async () => {
    if (editor.current!.getInstance().getMarkdown() === "") {
      alert("내용을 입력해주세요.");
      return;
    }
    try {
      await axios.post(
        `${process.env.REACT_APP_API_HOST}/api/histories`,
        {
          data: {
            content: editor.current!.getInstance().getMarkdown(),
            users_permissions_user: user.id,
            post: post.id,
            memo: memo,
          },
        },
        {
          withCredentials: true,
          headers: {
            Authorization: window.localStorage.getItem("token") as string,
          },
        }
      );
      await axios.put(
        `${process.env.REACT_APP_API_HOST}/api/posts/${post.id}?populate=*`,
        {
          data: {
            content: `${editor.current!.getInstance().getMarkdown()}`,
            users_permissions_user: user.id,
          },
        },
        {
          withCredentials: true,
          headers: {
            Authorization: window.localStorage.getItem("token") as string,
          },
        }
      );
      navigate(-1);
    } catch (e) {
      alert("뭔가 문제가 생겼습니다.");
    }
  };
  return (
    <Layout>
      <S.Post>
        {post?.attributes.title && (
          <S.Header>
            <S.ItemTitle>
              <img src={post.attributes.icon} alt={post.attributes.title} />
              {post.attributes.category}
            </S.ItemTitle>
            {post.attributes.title_kr}
          </S.Header>
        )}
        {post?.attributes.content && user && user.isApproved && (
          <div style={{ width: "100%" }}>
            <Editor
              initialValue={post.attributes.content}
              height="600px"
              ref={editor}
              plugins={[codeSyntaxHighlight]}
            />
            <S.InputContainer>
              <S.Memo>수정 내용 요약</S.Memo>
              <S.Input value={memo} onChange={(e) => setMemo(e.target.value)} />
            </S.InputContainer>
            <S.ButtonConatiner>
              <S.DoneButton onClick={() => handleSubmit()}>
                등록
                <AiOutlineCheck />
              </S.DoneButton>
            </S.ButtonConatiner>
          </div>
        )}
      </S.Post>
    </Layout>
  );
};

export default PostEdit;
