import { Link } from "react-router-dom";
import styled from "styled-components";
import { BREAKPOINT, THEME_COLOR } from "../../constant";

export const Post = styled.div`
  width: 100%;
  max-width: 1000px;
  min-height: 100vh;
  padding: 3rem 2rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const EditButton = styled(Link)`
  background: none;
  border: none;
  border-radius: 1rem;
  font-weight: 700;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.2rem;
  font-size: 1.6rem;
  text-decoration: none;
  color: black;
  margin-left: 1rem;
`;

export const Header = styled.div`
  width: 100%;
  display: flex;
  font-size: 1.6rem;
  gap: 0.5rem;
  align-items: center;
  margin-bottom: 3rem;
  border-bottom: 1px solid ${THEME_COLOR.GRAYER};
  padding-bottom: 1rem;
`;

export const ItemTitle = styled.div`
  font-size: 1.8rem;
  display: flex;
  font-weight: 700;
  gap: 1rem;
  img {
    width: 20px;
    height: 20px;
    object-fit: cover;
  }
`;

export const User = styled.div`
  font-size: 1.6rem;
  margin-top: 2rem;
`;
