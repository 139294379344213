export const THEME_COLOR = {
  PRIMARY: "#C2201F",
  SECONDARY: "#F19296",
  GRAY: "#F1F1F1",
  GRAYER: "#EDEDED",
};

export const BREAKPOINT = 640;

export interface Section {
  id: number;
  attributes: {
    title: string;
    title_kr: string;
    subtitle: string;
    posts: { data: Post[] };
    priority: number;
  };
}

export interface Post {
  id: number;
  attributes: {
    category: string;
    title: string;
    title_kr: string;
    icon: string;
    content: string;
    publishedAt: string;
    users_permissions_user: {
      data: {
        id: string;
        attributes: { username: string; nickname: string; emoji: string };
      };
    };
  };
}

export interface User {
  id: number;
  username: string;
  email: string;
  nickname: string;
  student_id: string;
  isApproved: boolean;
  emoji: string;
}

export interface History {
  id: number;
  attributes: {
    content: string;
    memo: string;
    createdAt: string;
    users_permissions_user: {
      data: {
        id: "string";
        attributes: {
          username: string;
          nickname: string;
          emoji: string;
        };
      };
    };
    post: {
      data: {
        attributes: {
          icon: string;
          category: string;
          title: string;
          title_kr: string;
        };
      };
    };
  };
}

export const Categories = [
  {
    title: "Python",
    icon: "https://user-images.githubusercontent.com/52532871/150517334-2d03ce9e-da67-4ae4-abf1-525b4cfd9773.png",
  },
  {
    title: "C/C++",
    icon: "https://user-images.githubusercontent.com/52532871/150517853-0b9836ac-91db-428a-b558-b8caf1361455.png",
  },
  {
    title: "JavaScript",
    icon: "https://user-images.githubusercontent.com/52532871/150521100-54f352db-622f-4303-a499-7b880603a4a2.png",
  },
  {
    title: "Java",
    icon: "https://user-images.githubusercontent.com/52532871/150629602-05b2a409-57f7-4b75-9bc5-1759008f0a79.png",
  },
  {
    title: "Android",
    icon: "https://user-images.githubusercontent.com/52532871/150569454-2b2f36ed-32dc-4f1b-b2ef-05a304a1d5a7.png",
  },
  {
    title: "Pytorch",
    icon: "https://user-images.githubusercontent.com/52532871/150564530-7b0d6c0a-f790-44ed-ae4d-95fa890b1083.png",
  },
  {
    title: "Kaggle",
    icon: "https://user-images.githubusercontent.com/52532871/150565085-f88d98e9-2709-4168-a6e4-48588ff85ce2.png",
  },
  {
    title: "React",
    icon: "https://user-images.githubusercontent.com/52532871/150629762-200da902-a9e5-4593-a682-6a125330743f.png",
  },
  {
    title: "Vue",
    icon: "https://user-images.githubusercontent.com/52532871/150629778-6e04a819-378a-4b46-9b1f-3ea4bca54f1c.png",
  },
  {
    title: "Angular",
    icon: "https://user-images.githubusercontent.com/52532871/150629790-41e50348-30be-46e1-918a-e9b0e426f46a.png",
  },
  {
    title: "MySQL",
    icon: "https://user-images.githubusercontent.com/52532871/150629633-22dcac49-c517-43f5-83b1-b9a3ec20eba2.png",
  },
  {
    title: "MariaDB",
    icon: "https://user-images.githubusercontent.com/52532871/150629687-343c7f76-efbe-40ec-9170-c6277afc045b.png",
  },
  {
    title: "Express",
    icon: "https://user-images.githubusercontent.com/52532871/150559434-bfef6638-14ed-4264-bc37-ed483ba1fcac.png",
  },
  {
    title: "Koa",
    icon: "https://user-images.githubusercontent.com/52532871/150629751-9594f01a-051e-43d1-a6da-46c0510e2ebf.png",
  },
  {
    title: "Django",
    icon: "https://user-images.githubusercontent.com/52532871/150655914-734762c3-022a-4291-ad3f-82819a30bc06.png",
  },
  {
    title: "Spring",
    icon: "https://user-images.githubusercontent.com/52532871/150633819-1d6941e6-3be7-4b11-b931-b790d6d03d22.png",
  },
  {
    title: "Photoshop",
    icon: "https://user-images.githubusercontent.com/52532871/150630596-b0e84703-9b48-4530-a026-318b5aedcfbd.png",
  },
  {
    title: "Premier",
    icon: "https://user-images.githubusercontent.com/52532871/150630597-e8ad92be-83db-4c3e-8d93-91d8e3be63f8.png",
  },
  {
    title: "Unity3D",
    icon: "https://user-images.githubusercontent.com/52532871/150630705-13c96dfe-206c-4dca-af0b-b29cd684ffaf.png",
  },
];

export interface Emoji {
  emoji: string;
  unified: string;
}
