import { Link } from "react-router-dom";
import styled, { css } from "styled-components";
import { BREAKPOINT, THEME_COLOR } from "../../constant";

export const Main = styled.div`
  max-width: 100%;
  width: 1000px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 3rem 2rem;
`;

export const HistoriesList = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  row-gap: 1rem;
`;

export const HistoryItem = styled(Link)`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  color: black;
  text-decoration: none;
`;

export const HistoryDate = styled.div`
  font-size: 1.4rem;
  font-weight: 600;
`;

export const User = styled.div`
  font-size: 1.4rem;
  small {
    margin-left: 0.2rem;
  }
`;

interface DiffProps {
  isMinus?: boolean;
}

export const Diff = styled.div<DiffProps>`
  font-size: 1.4rem;
  color: ${(props) => (props.isMinus ? THEME_COLOR.PRIMARY : "#3b3")};
  ${(props) =>
    !props.isMinus &&
    css`
      ::before {
        content: "+";
      }
    `}
`;

export const Header = styled.div`
  width: 100%;
  display: flex;
  font-size: 1.6rem;
  gap: 0.5rem;
  align-items: center;
  margin-bottom: 3rem;
  border-bottom: 1px solid ${THEME_COLOR.GRAYER};
  padding-bottom: 1rem;
`;

export const ItemTitle = styled.div`
  font-size: 1.8rem;
  display: flex;
  font-weight: 700;
  gap: 1rem;
  img {
    width: 20px;
    height: 20px;
    object-fit: cover;
  }
`;
