import * as S from "./styles";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";
import Layout from "../../component/layout";
import { AiFillWarning } from "react-icons/ai";
import { UserContext } from "../../App";

const Login = () => {
  const redirect = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [isLoading, setIsLoading] = useState(true);
  const { setUserWithData } = React.useContext(UserContext);
  const validate = async () => {
    try {
      const result = await axios.get(
        `${
          process.env.REACT_APP_API_HOST
        }/api/passwordless/passwordless/login?loginToken=${searchParams.get(
          "loginToken"
        )}`
      );
      if (result.status === 200) {
        window.localStorage.setItem("token", `Bearer ${result.data.jwt}`);
        setUserWithData(result);
        redirect("/");
      }
    } catch (e) {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    validate();
  }, []);
  return (
    <Layout>
      <S.Main>
        {isLoading ? (
          <div className="lds-ring">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        ) : (
          <S.TextContainer>
            <AiFillWarning />
            <S.Text>만료되거나 유효하지 않은 토큰입니다.</S.Text>
          </S.TextContainer>
        )}
      </S.Main>
    </Layout>
  );
};

export default Login;
