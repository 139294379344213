import styled from "styled-components";
import { THEME_COLOR } from "../../constant";

export const Main = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Text = styled.div`
  font-weight: 700;
  font-size: 2rem;
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 3rem;
  svg {
    width: 5rem;
    height: 5rem;
    fill: ${THEME_COLOR.PRIMARY};
  }
`;
