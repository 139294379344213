import * as S from "./styles";
import Layout from "../../component/layout";
import React, { useContext, useEffect, useState } from "react";
import { Categories, Emoji, Section } from "../../constant";
import axios from "axios";
import {
  AiOutlineCheck,
  AiOutlineMinusCircle,
  AiOutlinePlusCircle,
} from "react-icons/ai";
import { UserContext } from "../../App";
import Picker from "emoji-picker-react";

const Main = () => {
  const [sections, setSections] = useState([] as Section[]);
  const [isNewModalOpen, setIsNewModalOpen] = useState(false);
  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);
  const [isLogin, setIsLogin] = useState(true);
  const [isNewCategoryOpen, setIsNewCategoryOpen] = useState(false);
  const [selectedSection, selectSection] = useState("언어");
  const [selectedCategory, selectCategory] = useState("Python");
  const [newCategory, setNewCategory] = useState("");
  const [title, setTitle] = useState("");
  const [url, setUrl] = useState("");
  const [username, setUsername] = useState("");
  const [nickname, setNickname] = useState("");
  const [email, setEmail] = useState("");
  const [student_id, setStudent_id] = useState("");
  const [chosenEmoji, setChosenEmoji] = useState(null as unknown as Emoji);
  const [isEmojiOpen, setIsEmojiOpen] = useState(false);
  const [urlError, setUrlError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { user, setUserWithData, logout, isUserLoading } =
    React.useContext(UserContext);
  const getSections = async () => {
    setIsLoading(true);
    const result = await axios.get(
      `${process.env.REACT_APP_API_HOST}/api/sections?populate=posts&sort=priority`
    );
    setSections(result.data.data);
    setIsLoading(false);
  };
  const login = async () => {
    try {
      const result = await axios.post(
        `${process.env.REACT_APP_API_HOST}/api/passwordless/passwordless/send-link`,
        { email: email },
        { withCredentials: true }
      );
      if (result.status === 200) {
        alert("메일함을 확인해주세요.");
        setIsLoginModalOpen(false);
      }
    } catch (e) {
      alert("가입되지 않은 이메일입니다.");
    }
  };
  const register = async () => {
    try {
      const result = await axios.post(
        `${process.env.REACT_APP_API_HOST}/api/auth/local/register`,
        {
          username: username,
          email: email,
          nickname: nickname,
          student_id: student_id,
          password: Math.random().toString(36),
          emoji: chosenEmoji.emoji,
        }
      );
      setUserWithData(result);
      window.localStorage.setItem("token", `Bearer ${result.data.jwt}`);
      setIsLoginModalOpen(false);
    } catch (e) {
      alert("이미 가입된 이메일이거나 오류가 발생했습니다.");
    }
  };
  useEffect(() => {
    getSections();
  }, [isNewModalOpen]);
  const handleSubmit = async () => {
    try {
      const checkResult = await axios.get(
        `${process.env.REACT_APP_API_HOST}/api/posts?filters[title][$eq]=${url}`
      );
      console.log(checkResult.data.data.length);
      if (checkResult.data.data.length > 0) {
        setUrlError(true);
        return;
      }
      const result = await axios.post(
        `${process.env.REACT_APP_API_HOST}/api/posts?populate=*`,
        {
          data: {
            content: `# ${title}`,
            title: url,
            title_kr: title,
            section: sections.filter(
              (s) => s.attributes.title_kr === selectedSection
            )[0].id,
            category: isNewCategoryOpen ? newCategory : selectedCategory,
            icon:
              Categories.filter((c) => c.title === selectedCategory)[0]?.icon ||
              "/logo192.png",
            users_permissions_user: user,
          },
        },
        {
          withCredentials: true,
          headers: {
            Authorization: window.localStorage.getItem("token") as string,
          },
        }
      );
      const c = await axios.post(
        `${process.env.REACT_APP_API_HOST}/api/histories?populate=*`,
        {
          data: {
            content: `# ${title}`,
            users_permissions_user: user.id,
            post: result.data.data.id,
            memo: "문서 생성",
          },
        },
        {
          withCredentials: true,
          headers: {
            Authorization: window.localStorage.getItem("token") as string,
          },
        }
      );
      console.log(c);
      setIsNewModalOpen(false);
      setIsNewCategoryOpen(false);
    } catch (e) {
      alert("뭔가 문제가 생겼습니다.");
    }
  };
  const onEmojiClick = (e: any, emojiObject: Emoji) => {
    setChosenEmoji(emojiObject);
    setIsEmojiOpen(false);
  };
  return (
    <Layout>
      <S.Main>
        {user ? (
          !isUserLoading && (
            <S.LoginInfo>
              <div>
                {user.emoji}
                {user.username}
                {user.nickname && <small>({user.nickname})</small>}
              </div>
              {!user.isApproved && (
                <div>
                  <small>승인 대기 중</small>
                </div>
              )}
              <button onClick={() => logout()}>로그아웃</button>
            </S.LoginInfo>
          )
        ) : (
          <S.LoginButtonContainer>
            <S.LoginButton
              onClick={() => {
                setIsLoginModalOpen(true);
                setIsLogin(true);
              }}
            >
              로그인
            </S.LoginButton>
            <S.SignupButton
              onClick={() => {
                setIsLoginModalOpen(true);
                setIsLogin(false);
              }}
            >
              회원가입
            </S.SignupButton>
          </S.LoginButtonContainer>
        )}
        {isLoading ? (
          <S.LoadingContainer>
            <div className="lds-ring">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </S.LoadingContainer>
        ) : (
          <S.StudyList>
            {sections.map((section, index) => (
              <div key={index}>
                <S.SectionHeader>
                  <S.SectionTitle>
                    {section.attributes.title_kr}
                    <small>{section.attributes.title}</small>
                  </S.SectionTitle>
                  {user && user.isApproved && (
                    <S.AddButton
                      onClick={() => {
                        selectSection(section.attributes.title_kr);
                        setIsNewModalOpen(true);
                      }}
                    >
                      <AiOutlinePlusCircle />
                    </S.AddButton>
                  )}
                </S.SectionHeader>
                <S.SectionSubtitle>
                  {section.attributes.subtitle}
                </S.SectionSubtitle>
                <S.ItemContainer>
                  {section.attributes.posts.data
                    .sort((a, b) => {
                      if (
                        new Date(a.attributes.publishedAt).getTime() <
                        new Date(b.attributes.publishedAt).getTime()
                      ) {
                        return -1;
                      }
                      return 1;
                    })
                    .map((post, index) => (
                      <S.Item
                        to={{
                          pathname: `post/${post.attributes.title}`,
                        }}
                        state={{ id: post.attributes.title }}
                        key={index}
                      >
                        <S.ItemTitle>
                          <img
                            src={post.attributes.icon}
                            alt={post.attributes.title}
                          />
                          {post.attributes.category}
                        </S.ItemTitle>
                        {post.attributes.title_kr}
                      </S.Item>
                    ))}
                </S.ItemContainer>
              </div>
            ))}
          </S.StudyList>
        )}
      </S.Main>
      {isNewModalOpen && (
        <S.ModalContainer
          onClick={(e) => {
            if (e.target === e.currentTarget) {
              setIsNewModalOpen(false);
              setIsNewCategoryOpen(false);
            }
          }}
        >
          <S.Modal>
            <S.ModalText>새 커리큘럼 만들기</S.ModalText>
            <S.ModalSection>
              <S.ModalSectionTitle>섹션</S.ModalSectionTitle>
              <S.Dropdown
                value={selectedSection}
                onChange={(e) => {
                  selectSection(e.target.value);
                }}
              >
                {sections.map((section, index) => (
                  <option key={index}>{section.attributes.title_kr}</option>
                ))}
              </S.Dropdown>
            </S.ModalSection>
            <S.ModalSection>
              <S.ModalSectionTitle>카테고리</S.ModalSectionTitle>
              <img
                src={
                  Categories.filter((c) => c.title === selectedCategory)[0]
                    ?.icon || "/logo192.png"
                }
                alt={
                  Categories.filter((c) => c.title === selectedCategory)[0]
                    ?.title || ""
                }
              />
              <S.Dropdown
                value={selectedCategory}
                onChange={(e) => {
                  selectCategory(e.target.value);
                }}
                disabled={isNewCategoryOpen}
              >
                {Categories.map((category, index) => (
                  <option key={index}>{category.title}</option>
                ))}
              </S.Dropdown>
              <S.AddButton
                onClick={() => setIsNewCategoryOpen(!isNewCategoryOpen)}
              >
                {isNewCategoryOpen ? (
                  <AiOutlineMinusCircle />
                ) : (
                  <AiOutlinePlusCircle />
                )}
              </S.AddButton>
            </S.ModalSection>
            {isNewCategoryOpen && (
              <S.ModalSection>
                <S.ModalSectionTitle>새 카테고리</S.ModalSectionTitle>
                <S.ModalTitle
                  value={newCategory}
                  onChange={(e) => setNewCategory(e.target.value)}
                />
              </S.ModalSection>
            )}
            <S.ModalSection>
              <S.ModalSectionTitle>제목</S.ModalSectionTitle>
              <S.ModalTitle
                value={title}
                onChange={(e) => {
                  setTitle(e.target.value);
                }}
              />
            </S.ModalSection>
            <S.ModalSection>
              <S.ModalSectionTitle>URL</S.ModalSectionTitle>
              <S.ModalTitle
                value={url}
                isError={urlError}
                onChange={(e) => {
                  setUrl(e.target.value);
                  setUrlError(false);
                }}
              />
            </S.ModalSection>
            {urlError && (
              <S.ErrorMessage>이미 존재하는 URL입니다.</S.ErrorMessage>
            )}
            <S.ButtonConatiner>
              <S.DoneButton
                onClick={() => {
                  handleSubmit();
                }}
              >
                등록
                <AiOutlineCheck />
              </S.DoneButton>
            </S.ButtonConatiner>
          </S.Modal>
        </S.ModalContainer>
      )}
      {isLoginModalOpen && (
        <S.ModalContainer
          onClick={(e) => {
            if (e.target === e.currentTarget) {
              setIsLoginModalOpen(false);
            }
          }}
        >
          {isLogin ? (
            <S.Modal>
              <S.ModalText>로그인</S.ModalText>
              <S.ModalSection>
                <S.ModalSectionTitle>이메일</S.ModalSectionTitle>
                <S.ModalTitle
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </S.ModalSection>
              <S.DoneButton
                onClick={() => {
                  login();
                }}
              >
                로그인
                <AiOutlineCheck />
              </S.DoneButton>
            </S.Modal>
          ) : (
            <S.Modal>
              <S.ModalText>회원가입</S.ModalText>
              <S.ModalSection>
                <S.ModalSectionTitle>이메일</S.ModalSectionTitle>
                <S.ModalTitle
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                />
              </S.ModalSection>
              <S.ModalSection>
                <S.ModalSectionTitle>이름</S.ModalSectionTitle>
                <S.ModalTitle
                  value={username}
                  onChange={(e) => {
                    setUsername(e.target.value);
                  }}
                />
              </S.ModalSection>
              <S.ModalSection>
                <S.ModalSectionTitle>닉네임</S.ModalSectionTitle>
                <S.ModalTitle
                  value={nickname}
                  onChange={(e) => {
                    setNickname(e.target.value);
                  }}
                />
              </S.ModalSection>
              <S.ModalSection>
                <S.ModalSectionTitle>학번</S.ModalSectionTitle>
                <S.ModalTitle
                  value={student_id}
                  onChange={(e) => {
                    setStudent_id(e.target.value);
                  }}
                />
              </S.ModalSection>
              <S.ModalSection>
                <S.ModalSectionTitle>이모지</S.ModalSectionTitle>
                <S.ChosenEmoji onClick={() => setIsEmojiOpen(true)}>
                  {chosenEmoji?.emoji}
                </S.ChosenEmoji>
                {isEmojiOpen && (
                  <S.Picker>
                    <Picker onEmojiClick={onEmojiClick} />
                  </S.Picker>
                )}
              </S.ModalSection>
              <S.DoneButton
                onClick={() => {
                  register();
                }}
              >
                등록
                <AiOutlineCheck />
              </S.DoneButton>
            </S.Modal>
          )}
        </S.ModalContainer>
      )}
    </Layout>
  );
};

export default Main;
