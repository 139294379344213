import React from "react";

import * as S from "./styles";

const Footer = () => {
  return (
    <>
      <S.Footer>
        <b>Made with ❤ by KUCC</b>
        <br />
        <div>회장 김현채 | 010-7105-1751</div>
        <div>부회장 양제현 | 010-4915-7545</div>
        <br />
        <div>anamkucc314@gmail.com</div>
        <br />
        <div>서울특별시 성북구 고려대로 105 고려대학교 학생회관 314호</div>
        <br />
        <div>Copyright ⓒ KUCC All rights reserved.</div>
      </S.Footer>
    </>
  );
};

export default Footer;
