import * as S from "./styles";
import Layout from "../../component/layout";
import { useParams } from "react-router-dom";
import { History, Post } from "../../constant";
import React, { useContext, useEffect, useRef, useState } from "react";
import axios from "axios";
import { Viewer } from "@toast-ui/react-editor";
import "@toast-ui/editor/dist/toastui-editor-viewer.css";
import { AiOutlineEdit, AiOutlineHistory } from "react-icons/ai";
//@ts-ignore
import codeSyntaxHighlight from "@toast-ui/editor-plugin-code-syntax-highlight/dist/toastui-editor-plugin-code-syntax-highlight-all.js";
import "../../component/global-style/prism-vscode.css";
import { UserContext } from "../../App";

const PostView = () => {
  const params = useParams();
  const [post, setPost] = useState(null as unknown as Post);
  const [history, setHistory] = useState(null as unknown as History);
  const getPost = async () => {
    const result = await axios.get(
      `${process.env.REACT_APP_API_HOST}/api/posts?filters[title][$eq]=${params.id}&populate=*`
    );
    if (!result.data.data[0]) window.location.href = "/";
    setPost(result.data.data[0]);
  };
  const getHistory = async () => {
    const result = await axios.get(
      `${process.env.REACT_APP_API_HOST}/api/histories/${params.historyId}?populate=*`
    );
    setHistory(result.data.data);
  };
  const { user } = React.useContext(UserContext);
  useEffect(() => {
    if (params.historyId) getHistory();
    else getPost();
    window.scrollTo({ top: 0 });
  }, []);
  return (
    <Layout>
      <S.Post>
        {params.historyId && history ? (
          <>
            <S.Header>
              <S.ItemTitle>
                <img
                  src={history.attributes.post.data.attributes.icon}
                  alt={history.attributes.post.data.attributes.title}
                />
                {history.attributes.post.data.attributes.category}
              </S.ItemTitle>
              {history.attributes.post.data.attributes.title_kr}
            </S.Header>
            <Viewer
              initialValue={history.attributes.content}
              plugins={[codeSyntaxHighlight]}
            />
          </>
        ) : (
          post?.attributes.title && (
            <S.Header>
              <S.ItemTitle>
                <img src={post.attributes.icon} alt={post.attributes.title} />
                {post.attributes.category}
              </S.ItemTitle>
              {post.attributes.title_kr}
              {user && user.isApproved && (
                <S.EditButton
                  to={{
                    pathname: `edit`,
                  }}
                  state={{ id: post.attributes.title }}
                >
                  <AiOutlineEdit />
                  편집
                </S.EditButton>
              )}
              <S.EditButton
                to={{
                  pathname: `history`,
                }}
                state={{ id: post.attributes.title }}
              >
                <AiOutlineHistory />
                편집 기록
              </S.EditButton>
            </S.Header>
          )
        )}
        <div style={{ width: "100%" }}>
          {post?.attributes.content && (
            <Viewer
              initialValue={post.attributes.content}
              plugins={[codeSyntaxHighlight]}
            />
          )}
        </div>
        {post && (
          <S.User>
            최종 수정:{" "}
            {post.attributes.users_permissions_user?.data.attributes.emoji}
            {post.attributes.users_permissions_user?.data.attributes.username}
            <small>
              (
              {post.attributes.users_permissions_user?.data.attributes.nickname}
              )
            </small>
          </S.User>
        )}
      </S.Post>
    </Layout>
  );
};

export default PostView;
