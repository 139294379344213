import * as S from "./styles";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useParams } from "react-router-dom";
import Layout from "../../component/layout";
import { AiFillWarning } from "react-icons/ai";
import { UserContext } from "../../App";
import { History, Post } from "../../constant";
import { format } from "date-fns";

const HistoryView = () => {
  const params = useParams();
  const { user } = React.useContext(UserContext);
  const [histories, setHistories] = useState([] as History[]);
  const [post, setPost] = useState(null as unknown as Post);
  const getPost = async () => {
    const result = await axios.get(
      `${process.env.REACT_APP_API_HOST}/api/posts?filters[title][$eq]=${params.id}&populate=*`
    );
    if (!result.data.data[0]) window.location.href = "/";
    setPost(result.data.data[0]);
  };
  const getHistories = async () => {
    try {
      const result = await axios.get(
        `${process.env.REACT_APP_API_HOST}/api/histories?filters[post][title][$eq]=${params.id}&populate=users_permissions_user`
      );
      console.log(result.data.data);
      setHistories(
        result.data.data.sort((a: History, b: History) => {
          if (
            new Date(a.attributes.createdAt).getTime() <
            new Date(b.attributes.createdAt).getTime()
          ) {
            return 1;
          }
          return -1;
        })
      );
    } catch (e) {}
  };
  useEffect(() => {
    getPost();
    getHistories();
  }, []);
  return (
    <Layout>
      <S.Main>
        {post?.attributes.title && (
          <>
            <S.Header>
              <S.ItemTitle>
                <img src={post.attributes.icon} alt={post.attributes.title} />
                {post.attributes.category}
              </S.ItemTitle>
              {post.attributes.title_kr}
            </S.Header>
            <S.HistoriesList>
              {histories.map((history, index) => (
                <S.HistoryItem
                  to={{
                    pathname: `${history.id}`,
                  }}
                  state={{
                    id: post?.attributes.title.length,
                    historyId: history.id,
                  }}
                  key={index}
                >
                  <S.HistoryDate>
                    {format(
                      new Date(history.attributes.createdAt),
                      `yyyy-MM-dd hh:mm:ss`
                    )}
                  </S.HistoryDate>
                  <S.User>{history.attributes.memo}</S.User>
                  <S.Diff
                    isMinus={
                      history.attributes.content.length -
                        (histories[index + 1]?.attributes.content.length || 0) <
                      0
                    }
                  >
                    {history.attributes.content.length -
                      (histories[index + 1]?.attributes.content.length || 0)}
                  </S.Diff>
                  <S.User>
                    {
                      history.attributes.users_permissions_user?.data.attributes
                        .emoji
                    }
                    {
                      history.attributes.users_permissions_user?.data.attributes
                        .username
                    }
                    <small>
                      (
                      {
                        history.attributes.users_permissions_user?.data
                          .attributes.nickname
                      }
                      )
                    </small>
                  </S.User>
                </S.HistoryItem>
              ))}
            </S.HistoriesList>
          </>
        )}
      </S.Main>
    </Layout>
  );
};

export default HistoryView;
