import styled from "styled-components";
import { BREAKPOINT, THEME_COLOR } from "../../constant";

export const Layout = styled.div`
  width: 100%;
  min-height: 100%;
  height: max-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-top: 0.5rem solid ${THEME_COLOR.PRIMARY};
  padding-bottom: 25rem;
  position: relative;
`;
