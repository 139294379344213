import { Link as ReactLink } from "react-router-dom";
import styled from "styled-components/macro";

import { BREAKPOINT, THEME_COLOR } from "../../constant";

export const Footer = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  display: flex;
  width: 100%;
  background: #eee;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 3rem 2rem;
  div {
    font-size: 1.6rem;
    text-align: center;
  }
  b {
    font-size: 1.6rem;
    text-align: center;
    font-weight: 700;
  }
  @media only screen and (min-width: ${BREAKPOINT}px) {
    padding: 3rem 5rem;
    align-items: flex-end;
  }
`;
